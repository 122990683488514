<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 d-flex justify-content-between align-items-center">
				<b-button
					v-if="!isHotelInfoScreen"
					:class="!showFilters ? 'btn-primary' : 'btn-secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<!-- Uncomment when needed -->
				<!-- <div v-if="canConfirm(admin)">
					<div class="h5 mb-0 text-center">
						{{ ticketSummary.total_tickets }}
					</div>
					<small class="text-muted text-uppercase font-weight-bold">{{ translate('summary_total_ticket') }}</small>
				</div>
				<div v-if="canConfirm(admin)">
					<div class="h5 mb-0 text-center">
						{{ ticketSummary.extra_guests }}
					</div>
					<small class="text-muted text-uppercase font-weight-bold">{{ translate('guest_number') }}</small>
				</div>
				<div v-if="canConfirm(admin)">
					<div class="h5 mb-0 text-center">
						{{ ticketSummary.total }}
					</div>
					<small class="text-muted text-uppercase font-weight-bold">{{ translate('total') }}</small>
				</div>-->
				<div v-if="canConfirm(admin)">
					<div class="h5 mb-0 text-center">
						{{ ticketSummary.confirmed }}
					</div>
					<small class="text-muted text-uppercase font-weight-bold">{{ translate('confirmed') }}</small>
				</div>
				<div v-if="canConfirm(admin)">
					<div class="h5 mb-0 text-center">
						{{ ticketSummary.pending }}
					</div>
					<small class="text-muted text-uppercase font-weight-bold">{{ translate('pending') }}</small>
				</div>
				<div v-if="canConfirm(admin)">
					<div class="h5 mb-0 text-center">
						{{ ticketSummary.total_attendats }}
					</div>
					<small class="text-muted text-uppercase font-weight-bold">{{ translate('total_attendants') }}</small>
				</div>
			</div>
			<div class="col">
				<b-button
					v-if="showUserFilter() && !isCheckInScreen && !isPaymentsInScreen"
					class="float-right"
					variant="primary"
					@click="downloadReport">
					<i class="fas fa-file-excel" /> {{ translate('export') }}
				</b-button>
				<!-- <b-button
					v-if="isHotelInfoScreen"
					class="float-right"
					variant="primary"
					@click="downloadHotelInformation">
					<i class="fas fa-file-excel" /> {{ translate('export') }}
				</b-button>-->
				<b-button
					v-if="$can('event_tickets', 'add') && !isCheckInScreen && !isPaymentsInScreen && !isHotelInfoScreen"
					class="float-right mr-2"
					variant="primary"
					@click="$router.push({ name: 'NewEventTicket'});">
					{{ translate('add') }}
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			v-if="!isHotelInfoScreen"
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div
						v-if="showUserFilter()"
						class="col-sm">
						<search
							v-model="filters.username"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor,customer,affiliate', validateCountry: false}"
							class="mb-3"
							q="label"
							return-key="username" />
					</div>
					<div
						class="col-sm">
						<div
							class="form-group">
							<label
								for="ticket_owner"
								class="label">{{ translate('ticket_owner') }}</label>
							<input
								id="ticket_owner"
								v-model="filters.ticket_owner"
								:placeholder="translate('ticket_owner')"
								type="text"
								name="ticket_owner"
								class="form-control">
						</div>
					</div>
					<div
						v-if="!isCheckInScreen && !isPaymentsInScreen"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="orderId"
								class="label">{{ translate('order_id') }}</label>
							<input
								id="orderId"
								v-model="filters.orderId"
								:placeholder="translate('type_order')"
								type="text"
								name="orderId"
								class="form-control">
						</div>
					</div>
					<div
						v-if="(!showUserFilter() || dateRange === dateRanges.custom) && !isCheckInScreen && !isPaymentsInScreen"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="event"
								class="label">{{ translate('event') }}</label>
							<select
								id="event"
								v-model="filters.event"
								name="event"
								class="form-control">
								<option :value="'' || undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="event in events"
									:key="event"
									:value="event">
									{{ translate(event) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="!showUserFilter() && dateRange !== dateRanges.custom && !isPaymentsInScreen"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									v-for="dateRangeInfo in dateRanges"
									:key="dateRangeInfo"
									:value="dateRangeInfo">
									{{ translate(dateRangeInfo) }}
								</option>
							</select>
						</div>
					</div>
				</div>
				<div
					v-if="(showUserFilter() || dateRange === dateRanges.custom) && !isCheckInScreen && !isPaymentsInScreen"
					class="row">
					<div
						v-if="showUserFilter() && dateRange !== dateRanges.custom"
						class="col-sm-4">
						<div
							class="form-group">
							<label
								for="event"
								class="label">{{ translate('event') }}</label>
							<select
								id="event"
								v-model="filters.event"
								name="event"
								class="form-control">
								<option :value="'' || undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="event in events"
									:key="event"
									:value="event">
									{{ translate(event) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="showUserFilter()"
						:class="dateRange === dateRanges.custom ? 'col-sm-3' : 'col-sm-4'">
						<div
							class="form-group">
							<label
								for="organization"
								class="label">{{ translate('organization') }}</label>
							<select
								id="organization"
								v-model="filters.organization"
								name="organization"
								class="form-control">
								<option :value="'' || undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="(leader, index) in leaders"
									:key="index"
									:value="leader.id">
									{{ leader.attributes.leader }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="showUserFilter() || dateRange === dateRanges.custom"
						:class="showUserFilter() && dateRange === dateRanges.custom ? 'col-sm-3' : 'col-sm-4'">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									v-for="dateRangeInfo in dateRanges"
									:key="dateRangeInfo"
									:value="dateRangeInfo">
									{{ translate(dateRangeInfo) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						:class="showUserFilter() ? 'col-sm-3' : 'col-sm-4'">
						<div
							class="form-group">
							<label
								for="startDate"
								class="label">{{ translate('start_date') }}</label>
							<v-date-picker
								id="startDate"
								v-model="startDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="startDate"
								show-caps />
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						:class="showUserFilter() ? 'col-sm-3' : 'col-sm-4'">
						<div
							class="form-group">
							<label
								for="endDate"
								class="label">{{ translate('end_date') }}</label>
							<v-date-picker
								id="endate"
								v-model="endDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="endDate"
								show-caps />
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<b-row>
			<b-col class="col-12">
				<b-tabs v-model="tabIndex">
					<b-tab
						v-for="(tabInfo, index) in tabs"
						:key="index"
						:lazy="true">
						<template slot="title">
							<router-link
								v-if="canConfirm(tabInfo.roles, tabInfo.permission_section, tabInfo.permission_action)"
								:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
								:to="{ name: tabInfo.name, query }"
								class="list-group-item btn text-left">
								{{ translate(tabInfo.translate_key) }}
							</router-link>
						</template>
						<template
							v-if="tabIndex === index"
							class="p-0">
							<router-view />
						</template>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import {
	admin, distributor, affiliate,
} from '@/settings/Roles';
import DataFilter from '@/components/DataFilter';
import Leaders from '@/util/Leaders';
import FiltersParams from '@/mixins/FiltersParams';
import {
	PERIOD_FILTERS as dateRanges,
	V_CALENDAR_CONFIG as calendarConfig,
	allTime,
	custom,
} from '@/settings/Dates';
import { EVENTS } from '@/settings/Events';
import {
	Events, EventTickets as Tickets, Grids,
} from '@/translations';
import { SEARCH_USERS } from '@/config/endpoint';
import EventTickets from '@/util/EventTickets';
import EventBus from '@/util/eventBus';

const { endpoint } = SEARCH_USERS;

export default {
	name: 'EventTickets',
	components: { DataFilter },
	messages: [Grids, Events, Tickets],
	mixins: [FiltersParams],
	data() {
		return {
			dateRanges,
			allTime,
			custom,
			calendarConfig,
			dateRange: allTime,
			admin,
			leadersData: new Leaders(),
			leaders: [],
			events: EVENTS,
			endpoint,
			checkInSummary: new EventTickets(),
			ticketSummary: { total: '0', pending: '0', confirmed: '0' },
			tabIndex: 0,
			tabs: [
				{
					name: 'EventTicketsCheckIn',
					translate_key: 'event_tickets_check_in',
					permission_section: 'event_tickets',
					permission_action: 'confirm',
					roles: admin,
				},
				{
					name: 'EventTicketsCheckedIn',
					translate_key: 'event_tickets_checked_in',
					permission_section: 'event_tickets',
					permission_action: 'confirm',
					roles: admin,
				},
				{
					name: 'EventTicketsAll',
					translate_key: 'event_tickets_all',
					permission_section: 'event_tickets',
					permission_action: 'view',
					roles: [...admin, distributor, affiliate],
				},
				// {
				// 	name: 'EventTicketsPayments',
				// 	translate_key: 'event_tickets_payments',
				// 	permission_section: 'event_tickets',
				// 	permission_action: 'view',
				// 	roles: [...admin, distributor, affiliate],
				// },
				// {
				// 	name: 'HotelInformation',
				// 	translate_key: 'hotel_information_report',
				// 	permission_section: 'event_tickets',
				// 	permission_action: 'view',
				// 	roles: [...admin],
				// },
			],
		};
	},
	computed: {
		query() {
			return this.$route.query;
		},
		isCheckInScreen() {
			return this.$route.name === 'EventTicketsCheckedIn' || this.isCheckInOne;
		},
		isHotelInfoScreen() {
			return this.$route.name === 'HotelInformation';
		},
		isCheckInOne() {
			return this.$route.name === 'EventTicketsCheckIn';
		},
		isPaymentsInScreen() {
			return this.$route.name === 'EventTicketsPayments';
		},
	},
	mounted() {
		if (this.$route.name === 'EventTickets') {
			// this.$router.push({ name: this.canConfirm(this.admin) ? 'EventTicketsCheckIn' : 'EventTicketsAll' }).catch(() => {});
			this.$router.push({ name: 'EventTicketsAll' }).catch(() => {});
		}
		EventBus.$on('update:tickets_summary', this.updateSummary);
		this.dateRanges.all_time = this.allTime;
		this.dateRanges.custom = this.custom;

		if (this.showUserFilter()) {
			this.leadersData.getLeaders().then((response) => {
				this.leaders = response;
			});
		}
		this.updateSummary();
	},
	beforeDestroy() {
		EventBus.$off('update:tickets_summary');
	},
	methods: {
		showUserFilter() {
			return this.admin.includes(this.$user.details().type);
		},
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}

			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			this.$router.push({ name: this.$route.name, query: options }).catch(() => {});
		},
		downloadReport() {
			const tickets = new EventTickets();
			const loading = new this.$Alert();
			loading.loading(this.translate('loading'), this.translate('loading_text'), { allowOutsideClick: false, allowEscapeKey: false });

			tickets.downloadTickets().then(() => {
				const { response } = tickets.data;
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.translate('purchased_tickets')} - ${this.$moment().format('YYYY-MM-DD')}.xlsx`);
				this.$el.appendChild(link);
				link.click();
				loading.close();
			}).catch(() => {
				loading.close();
			});
		},
		downloadHotelInformation() {
			const tickets = new EventTickets();
			const loading = new this.$Alert();
			loading.loading(this.translate('loading'), this.translate('loading_text'), { allowOutsideClick: false, allowEscapeKey: false });

			tickets.downloadHotelInformation().then(() => {
				const { response } = tickets.data;
				const url = window.URL.createObjectURL(response.data);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.translate('hotel_information_report')} - ${this.$moment().format('YYYY-MM-DD')}.xlsx`);
				this.$el.appendChild(link);
				link.click();
				loading.close();
			}).catch(() => {
				loading.close();
			});
		},
		updateSummary() {
			if (this.canConfirm(this.admin)) {
				let options = {};
				if (this.filters.event) {
					options = {
						event: this.filters.events,
					};
				}
				this.checkInSummary.getSummary(options).then((response) => {
					this.ticketSummary = { ...response.response.summary };
				});
			}
		},
		canConfirm(roles, section = 'event_tickets', action = 'confirm') {
			return roles.includes(this.$user.details().type) && this.$can(section, action);
		},
	},
};
</script>
