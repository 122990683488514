<template>
	<i
		:class="classIcon"
		class="pl-2" />
</template>
<script>
import {
	ASC_ORDER as orderDef,
	ORDER_FIELD_NAME as orderField,
	ORDER_DIR_NAME as orderDir,
} from '@/settings/RequestReply';
import {
	DEFAULT_ORDER_ICON as defaultIcon,
	FIRST_ORDER_ICON as firstOrderIcon,
	SECOND_ORDER_ICON as secondOrderIcon,
} from '@/settings/Icons';

export default {
	props: {
		importData: {
			type: Object,
			default() {
				return {};
			},
		},
		field: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			data: {},
		};
	},
	computed: {
		direction() {
			return this.data[orderDir];
		},
		orderedByMe() {
			return this.data[orderField] === this.field;
		},
		classIcon() {
			if (!this.orderedByMe) {
				return defaultIcon;
			}
			return this.direction === orderDef ? firstOrderIcon : secondOrderIcon;
		},
	},
	watch: {
		importData() {
			this.init();
		},
		'$route.query': {
			handler() {
				this.init();
			},
		},
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			this.data = Object.keys(this.importData).length ? { ...this.importData } : { ...this.$route.query };
		},
	},
};
</script>
