export const DEFAULT_ORDER_ICON = 'fas fa-sort';
export const FIRST_ORDER_ICON = 'fas fa-sort-up';
export const SECOND_ORDER_ICON = 'fas fa-sort-down';
export const ERROR_ICON = 'icon-close text-primary';
export const SUCCESS_ICON = 'icon-check text-primary';
export const INFO_ICON = 'fa-info-circle text-primary';
export const INVALID_ICON = 'fa-info-circle text-primary';

export const DEFAULT_CURRENCY_ICON = 'fa-dollar-sign';
export const CURRENCY_ICONS = {
	CAD: 'fa-dollar-sign',
	USD: 'fa-dollar-sign',
	EUR: 'fa-euro-sign',
	JPY: 'fa-yen-sign',
	MXN: 'fa-dollar-sign',
	HUF: 'fa-dollar-sign',
	BGN: 'fa-dollar-sign',
	HRK: 'fa-dollar-sign',
	CZK: 'fa-dollar-sign',
	DKK: 'fa-dollar-sign',
	PLN: 'fa-dollar-sign',
	RON: 'fa-dollar-sign',
	GBP: 'fa-pound-sign',
	SEK: 'fa-dollar-sign',
};
