<template>
	<form
		v-show="display"
		:id="formId"
		class="mb-3"
		@submit.prevent="emitValues">
		<slot name="form" />
		<div class="row">
			<div class="col">
				<input
					:value="translate('submit')"
					type="submit"
					class="btn bg-primary-alt btn-primary mr-1">
				<b-button
					variant="secondary"
					@click="clearFilter">
					{{ translate('clear') }}
				</b-button>
			</div>
		</div>
	</form>
</template>
<script>
/* eslint arrow-parens: ["error", "always"] */

export default {
	name: 'DataFilter',
	props: {
		getBy: {
			type: String,
			default: 'name',
			validator: (val) => ['name', 'id'].includes(val),
		},
		display: {
			type: Boolean,
			default: false,
		},
		formId: {
			type: String,
			default: 'filter-form',
		},
	},
	watch: {
		display(value) {
			if (value) {
				this.$nextTick(() => {
					document.getElementById(this.formId).querySelectorAll('input')[0].focus();
				});
			}
		},
	},
	mounted() {
		if (this.display) {
			this.$nextTick(() => {
				document.getElementById(this.formId).querySelectorAll('input')[0].focus();
			});
		}
	},
	methods: {
		clearFilter() {
			this.$emit('clear');
		},
		emitValues(event) {
			const { target } = event;
			const response = {};
			Object.keys(target).forEach((item) => {
				if (typeof target[item] === 'object') {
					if (typeof target[item][this.getBy] !== 'undefined' && target[item][this.getBy].trim() !== '') {
						response[target[item][this.getBy]] = target[item].value;
					}
				}
			});
			this.$emit('submit', response);
		},
	},
};
</script>
